import { UserManager } from 'oidc-client';
import { IDENTITY_CONFIG } from './identityConfig';


const userManager = new UserManager(IDENTITY_CONFIG);

export function signinRedirect(loginHint, auth_method) {
  if (auth_method) {
    return userManager.signinRedirect({
      acr_values: auth_method,
      login_hint: loginHint
    });
  } else {
    return userManager.signinRedirect((loginHint ? { login_hint: loginHint } : null));
  }
}

export function signinRedirectCallback() {
  return userManager.signinRedirectCallback()
}

export function signinSilentCallback() {
  userManager.signinSilentCallback();
};

export async function signoutRedirect() {
  const user = await userManager.getUser();
  const userSetting = { idp: user.profile.idp, locale: user.profile.locale };
  return userManager.signoutRedirect({
    id_token_hint: user && user.id_token,
    state: userSetting
  });
}

function clearOIDCStorage() {
  const oidcPrefix = 'oidc.';
  for (let i = localStorage.length - 1; i >= 0; i--) {
    const key = localStorage.key(i);
    if (key && key.startsWith(oidcPrefix)) {
      localStorage.removeItem(key);
    }
  }
}

export async function signoutRedirectCallback() {
  try {
    return await userManager.signoutRedirectCallback();
  } catch (err) {
    console.error('Error during signout redirect callback:', err);
  } finally {
    clearOIDCStorage();
    await userManager.removeUser();
  }
}

export default userManager