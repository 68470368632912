import React, { useEffect, useRef } from "react";
import {
  signoutRedirectCallback,
  signinRedirect,
} from "utils/oidcClientExtension";
import { onPostUserLogoutMessage } from "utils/broadcastChannelUtils";
import { useAsyncError } from "hooks/useAsyncError";

function SignoutOidc() {
  const throwError = useAsyncError();
  const redirectCallbackPromiseRef = useRef(null);

  const redirectCallback = async () => {
    if (!redirectCallbackPromiseRef.current) {
      redirectCallbackPromiseRef.current = signoutRedirectCallback();
    }

    return redirectCallbackPromiseRef.current;
  }

  useEffect(() => {
    async function signoutAsync() {
      try {
        var signOutResponse = await redirectCallback();
        var state = signOutResponse != null && signOutResponse.state;
        onPostUserLogoutMessage();
        if (state) {
          await signinRedirect("", "authm=" + state.idp + " locale=" + state.locale);
        } else {
          await signinRedirect();
        }
      } catch (e) {
        throwError(e);
      }
    }

    signoutAsync();
  }, [throwError]);

  return <></>;
}

export default SignoutOidc;
